<template>
  <AppCard>
    <template #header>
      <AppCardHeader class="header-content">
        <template #left>
          <AppBackButton
            class="back-button"
            :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
            analytics-name="send-money-verification-back"
            icon="<"
            @click="goBackOneStep"
          />
        </template>
        <AppFigure class="iconFigure">
          <AppIcon name=" " class="icon">
            <IconIdentification />
          </AppIcon>
        </AppFigure>
        {{ $t('PageSendMoneyAdditionalDetails.Title').value }}
      </AppCardHeader>
    </template>

    <div>
      <p class="content">
        {{ $t('PageSendMoneyAdditionalDetails.WeNeedMoreInformationText').value }}
      </p>
    </div>

    <template #footer>
      <AppCardFooter>
        <AppButton
          :loading="isProcessingOrder"
          analytics-name="send-money-verification-continue"
          @click="submit"
        >
          {{ $t('PageSendMoneyAdditionalDetails.ContineuButtonText').value }}
        </AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { usePromiseLazy } from 'vue-composable'
import { reactive, computed } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

/* Components */

import {
  useMediaQuery,
  AppButton,
  AppCard,
  AppCardFooter,
  AppCardHeader,
  AppBackButton,
  AppFigure,
  AppIcon,
} from '@oen.web.vue2/ui'

import { IconIdentification } from '@oen.web.vue2/icons'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyVerification',
  components: {
    AppCardFooter,
    AppCard,
    AppButton,
    IconIdentification,
    AppFigure,
    AppIcon,
    AppCardHeader,
    AppBackButton,
  },
  setup() {
    const router = useRouter()
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    // Tell the store which step we are on
    sendMoneyStore.setActiveStep(router.currentRoute.path)

    // Return the step data to the component
    const activeStep = sendMoneyStore.activeStep

    const goBackOneStep = () => {
      router.replace('/send-money/summary')
    }

    const processOrder = reactive(
      usePromiseLazy(() =>
        sendMoneyStore.processOrderSteps({
          transferId: sendMoneyStore.form.transferId,
        })
      )
    )

    const submit = async () => {
      await processOrder.exec()
      if (processOrder.error) {
        console.log('err:', processOrder.error)
      } else {
        router.replace(processOrder.result)
      }
    }

    const goToActivityPage = () => {
      router.push('/Activity')
    }

    return {
      mq: useMediaQuery(),
      submit,
      activeStep,
      goToActivityPage,
      $t,
      goBackOneStep,
      isProcessingOrder: computed(() => processOrder.loading),
    }
  },
}
</script>

<style scoped>
.card-footer {
  @apply flex flex-col;
}

::v-deep .card-header .card-header-title {
  @apply flex flex-col h-auto text-2xl font-medium;
}

.content {
  @apply text-center;
}

.iconFigure {
  @apply h-8 w-9 m-auto bg-transparent border-none;
  color: var(--theme-color-primary, theme('colors.blue.default'));
}

.icon {
  @apply h-8 w-9;
}

.back-button {
  @apply w-0 h-0;
}
.header-content {
  @apply items-baseline;
}
</style>
